.po_items_ho {
    column-width: 15em;
    -moz-column-width: 15em;
    -webkit-column-width: 15em;
    column-gap: 4rem;
    -moz-column-gap: 4rem;
    -webkit-column-gap: 4rem;
}

.po_item {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0.5rem auto;
    position: relative;
    background: var(--secondary-color);
    padding: 6px;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    font-size: 0;
    min-height: 300px;
}

.po_item img {
    max-width: 100%;
}

.po_item .content {
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--overlay-color);
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
    border: 1px solid black;
}

.po_item .content {
    opacity: 0;
    transition-delay: 1s;
    transition: 0.3s ease;
    font-size: 20px;
}

.po_item:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    opacity: 1;
}

.po_item:hover .content {
    opacity: 1;
    color: var(--secondary-color);
}

.po_item .content a {
    background: var(--bg-color);
    border: solid 1px var(--text-color);
    padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
}

.po_item .content a:hover {
    text-decoration: none;
}

.portfolio-title{
	z-index: 999;
	height: 25px;
    align-content: center;
    margin-top: 50px;

    width: 100%;
    left: 0;
}


.portfolio-oldstuff-subtitle{
	z-index: 999;
	height: 25px;
    align-content: center;

    width: 100%;
    left: 0;
}



.portfolio-project-title{
    position: relative;
    height: 100%;
    top: 50%;
    width: 90%;
        text-align: left;
    align-content: center;
    transform: translate(0%, -50%);
    z-index: 999;
	margin-left: 00px;
}

.portfolio-video-square{
	z-index: 99;
    position: relative;
	  left: 50%;
    width: 320px;
	  height: 320px;
    border: 5px solid white;
    transform: translate(-50%, 0%);
}


.portfolio-video-square-reduced{
	z-index: 99;
    position: relative;
	  left: 50%;
    width: 320px;
	  height: 320px;
    border: 5px solid white;
    transform: translate(-50%, 0%);
}

.portfolio-video-portrait{
	z-index: 99;
    position: relative;
	  left: 50%;
    width: 320px;
	  height: 640px;
    border: 5px solid white;
    transform: translate(-50%, 0%);
}

@media (min-width: 800px) {
    .portfolio-project-title{
        left: 0px;
        transform: translate(0%, -50%);
        text-align: left;
    }
    .portfolio-title{
        width: 100%;
        left: 0;
    }
    .portfolio-video-square{
      width: fit-content;
      height: fit-content;
    }
    .portfolio-video-portrait{
      width: fit-content;
      height: fit-content;
    }
}

.logo-icon{
	z-index: 999;
	margin: 10px;
	height: 32px;
    left: 50%;
}

.portfolio-tabs{
	z-index: 999;
	margin: 20px;
}




.portfolio-section {
    margin-bottom: calc(3rem + 5.128vw);
}

.portfolio-container{
    top: 500px;
}

.horizontalList {
    width:  100%;
    list-style-type: none;
    list-style: false;
    position: relative;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    top: 50px;
}


.feature-button {
    padding: 4px 19px;
    color: var(--primary-color);
    position: relative;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    border-radius: 20px;
    margin: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    display: inline-block;
}


.list-container {
    text-align: center;
    margin-right: 50px;
}

.list-container .list-item {
    display: inline-block;
}


.portfolio-tab{
	width: 90%;
	margin-left: 25px;

}


.portfolio-tabs{
	z-index: 999;
	width: 100%;
	left: 50%;
}

.portfolio-tab-container{
	width: 100vw;
	margin: 0px;
}
